import axios from "axios";
// const URL = "http://localhost:8080/Api";

// pre-prod
// const URL = "https://kris-bang.alwaysdata.net/api-inscription-online-gta/Api";

//prod new
const URL =
  "https://inscription-online.alwaysdata.net/api-inscription-online-gta-93/Api";

const instance = axios.create({
  baseURL: URL,
});

if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
  // console.log("localStorage.getItem(token)", localStorage.getItem("token"));
  instance.defaults.headers.common["Authorization"] = `${localStorage.getItem(
    "token"
  )}`;
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // console.log("error", error);
    if (
      error.config.url != "/user/membre/checkToken" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (
        localStorage.getItem("refreshToken") &&
        localStorage.getItem("refreshToken") != ""
      ) {
        instance.defaults.headers.common[
          "Authorization"
        ] = `${localStorage.getItem("refreshToken")}`;
        // console.log("refreshToken");
        instance
          .get("/user/membre/checkToken")
          .then((response) => {
            // console.log("response", response);
            // TODO: mettre à jour l'accessToken dans le localStorage
            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem(
              "refreshToken",
              response.data.accessTokenRefresh
            );
            originalRequest.headers[
              "Authorization"
            ] = `${response.data.accessTokenRefresh}`;
            instance.defaults.headers.common[
              "Authorization"
            ] = `${response.data.accessTokenRefresh}`;
          })
          .catch((err) => {
            localStorage.setItem("refreshToken", null);
            console.log(err);
          });
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default {
  instance: instance,
  api_url: URL,
};
