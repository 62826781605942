import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Inscription from "@/services/Inscription";
import moment from "moment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/lockHome",
    name: "lockHome",
    component: () =>
      import(/* webpackChunkName: "lock" */ "../views/LockHomeView.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: { requiresActif: true },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/enfant",
    name: "enfant",
    meta: { requiresActif: true },
    component: () =>
      import(/* webpackChunkName: "enfant" */ "../views/EnfantView.vue"),
  },
  {
    path: "/adulte",
    name: "adulte",
    meta: { requiresActif: true },
    component: () =>
      import(/* webpackChunkName: "adulte" */ "../views/AdulteView.vue"),
  },
  {
    path: "/reglement",
    name: "reglement",
    meta: { requiresActif: true },
    component: () =>
      import(/* webpackChunkName: "reglement" */ "../views/ReglementView.vue"),
  },
  {
    path: "/informations-legales",
    name: "infosLegales",
    meta: { requiresActif: true },
    component: () =>
      import(/* webpackChunkName: "infos" */ "../views/InfosLegalesView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/compte",
    name: "compte",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "compte" */ "../views/CompteView.vue"),
  },
  {
    path: "/profil",
    name: "profil",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/ProfilCompteView.vue"),
  },
  {
    path: "/document",
    name: "document",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "document" */ "../views/DocumentCompteView.vue"
      ),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  // routes,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Gestion Lock home
  let config = await Inscription.getActivate();
  const startD = moment(config.startDate).format("YYYY-MM-DD");
  const endD = moment(config.endDate).format("YYYY-MM-DD");
  const now = moment().format("YYYY-MM-DD");
  const isBetween = moment(now).isBetween(
    moment(startD),
    moment(endD),
    undefined,
    "[]"
  );

  if (to.matched.some((record) => record.meta.requiresActif)) {
    if (isBetween) {
      next();
      return;
    } else {
      next("/lockHome");
    }
  }
  // Gestion Lock home
  // Getion acces des comptes
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Inscription/isAuthenticated"]) {
      next();
      return;
    } else {
      let userGet = null;
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token") != ""
      ) {
        // userGet = await Inscription.getCheckToken();
        userGet = await Inscription.getCheckToken({
          token: localStorage.getItem("token"),
          compteType: localStorage.getItem("compteType"),
        });
      }

      if (userGet) {
        // console.log("store", store);
        // store.state.user = true;
        store.state.Inscription.lastUserConfig = userGet.lastConfig;

        store.dispatch("Inscription/setUser", true);
        store.dispatch("Inscription/setUserAccount", userGet.allUser);
        store.dispatch("Inscription/setUserInfo", userGet.user);
        store.dispatch("Inscription/setUserPaiement", userGet.paiement);
        next();
        return;
      } else {
        next("/login");
      }
    }
  } else {
    next();
  }
  // Getion acces des comptes
});

export default router;
