export default {
  namespaced: true,
  state: {
    ref: "",
    yearInscription: null,
    saison: "",
    startDate: "",
    endDate: "",
    // yearInscription: 2022,
    // saison: "2022-2023",
    modal: false,
    modalContent: {
      titre: "",
      corps: "",
    },
    tabs: "tabs-profil",
    user: null,
    userAccount: [],
    userInfo: {},
    userPaiement: {},
    lastUserConfig: {},
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    getRef: (state) => {
      return state.ref;
    },
    getYearInscription: (state) => {
      return state.yearInscription;
    },
    getSaison: (state) => {
      return state.saison;
    },
    getModal: (state) => {
      return state.modal;
    },
    getModalContent: (state) => {
      return state.modalContent;
    },
  },
  actions: {
    setUserPaiement({ commit }, datas) {
      commit("SET_USER_PAIEMENT", datas);
    },
    setUserInfo({ commit }, datas) {
      commit("SET_USER_INFO", datas);
    },
    setUserAccount({ commit }, datas) {
      commit("SET_USER_ACCOUNT", datas);
    },
    setUser({ commit }, datas) {
      commit("SET_USER", datas);
    },
    setModelModal({ commit }, datas) {
      commit("SET_MODEL_MODAL", datas);
    },
    setModalContent({ commit }, datas) {
      commit("SET_MODAL_CONTENT", datas);
    },
    setConfigInscription({ commit }, datas) {
      commit("SET_CONFIG_INSCRIPTION", datas);
    },
  },
  mutations: {
    SET_USER_PAIEMENT(state, datas) {
      state.userPaiement = datas;
    },
    SET_USER_INFO(state, datas) {
      state.userInfo = datas;
    },
    SET_USER_ACCOUNT(state, datas) {
      state.userAccount = datas;
    },
    SET_USER(state, datas) {
      state.user = datas;
    },
    SET_CONFIG_INSCRIPTION(state, datas) {
      state.yearInscription = datas.yearInscription;
      state.saison = datas.saison;
      state.ref = datas.ref;
      state.startDate = datas.startDate;
      state.endDate = datas.endDate;
    },
    SET_MODEL_MODAL(state, datas) {
      state.modal = datas;
    },
    SET_MODAL_CONTENT(state, datas) {
      if (datas == 1) {
        state.modalContent.titre = "";
        state.modalContent.corps = ``;
      }
      if (datas == 2) {
        state.modalContent.titre = "";
        state.modalContent.corps = ``;
      }
    },
  },
};
