export default {
  namespaced: true,
  state: {
    startAge: 15,
    resetAdulte: false,
    incrementAdulte: {
      init: 1,
      max: 8,
    },

    saveInscriptionAdulte: {
      cotisation: null,
      niveau: [],
      // niveau: "",
      personnalInformation: { firstname: "" },
      creneau: [],
      ref: "",
    },

    horaireDataAdulte: [
      // {
      //   location: "Moulin à vent",
      //   adresse: "",
      //   niveau: "loisir",
      //   description: "description 1",
      //   ref: "MVA",
      //   readonly: true,
      //   days: [
      //     {
      //       ref: "MVA1",
      //       jour: "mardi",
      //       creneau: "18h45/19h45",
      //       description: "description day 1",
      //       readonly: true,
      //     },
      //     {
      //       ref: "MVA2",
      //       jour: "mercredi",
      //       creneau: "18h00/19h00",
      //       description: "description day 2",
      //       readonly: true,
      //     },
      //     {
      //       ref: "MVA3",
      //       jour: "jeudi",
      //       creneau: "18h00/19h00",
      //       description: "description day 3",
      //       readonly: true,
      //     },
      //   ],
      // },
      // {
      //   location: "Touleuse",
      //   adresse: "",
      //   niveau: "loisir",
      //   description: "description 1",
      //   ref: "TMVA",
      //   readonly: true,
      //   days: [
      //     {
      //       ref: "TMVA1",
      //       jour: "mardi",
      //       creneau: "18h45/19h45",
      //       description: "description day 1",
      //       readonly: true,
      //     },
      //     {
      //       ref: "TMVA2",
      //       jour: "mercredi",
      //       creneau: "18h00/19h00",
      //       description: "description day 2",
      //       readonly: true,
      //     },
      //     {
      //       ref: "TMVA3",
      //       jour: "jeudi",
      //       creneau: "18h00/19h00",
      //       description: "description day 3",
      //       readonly: true,
      //     },
      //   ],
      // },
      // {
      //   location: "Les Grès",
      //   adresse: "",
      //   niveau: "body taek fit",
      //   description: "description 2",
      //   ref: "GA",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "GA1",
      //       jour: "lundi",
      //       creneau: "20h00/21h30",
      //       description: "description day 1",
      //       readonly: false,
      //     },
      //     {
      //       ref: "GA2",
      //       jour: "mercredi",
      //       creneau: "20h00/21h30",
      //       description: "description day 2",
      //       readonly: false,
      //     },
      //   ],
      // },
      // {
      //   location: "Les Grès",
      //   adresse: "",
      //   niveau: "compétition",
      //   description: "description 2",
      //   ref: "GAC",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "GAC1",
      //       jour: "lundi",
      //       creneau: "20h00/21h30",
      //       description: "description day 1",
      //       readonly: false,
      //     },
      //     {
      //       ref: "GAC2",
      //       jour: "mercredi",
      //       creneau: "20h00/21h30",
      //       description: "description day 2",
      //       readonly: false,
      //     },
      //     {
      //       ref: "GAC3",
      //       jour: "jeudi",
      //       creneau: "20h00/21h30",
      //       description: "description day 3",
      //       readonly: false,
      //     },
      //   ],
      // },
      // {
      //   location: "Les Grès",
      //   adresse: "",
      //   niveau: "Séance à thème",
      //   description: "description 3",
      //   readonly: true,
      //   ref: "GAT",
      //   days: [
      //     {
      //       ref: "GAT1",
      //       jour: "samedi",
      //       creneau: "14h00/15h30",
      //       description: "description day 1",
      //       readonly: true,
      //     },
      //   ],
      // },
    ],

    levelDataAdulte: [
      // {
      //   niveau: "loisir",
      //   startAge: 15,
      //   NbTrainingByWeek: [1, 2],
      // },
      // {
      //   niveau: "body taek fit",
      //   startAge: 15,
      //   NbTrainingByWeek: [1, 2],
      // },
      // {
      //   niveau: "compétition",
      //   startAge: 15,
      //   NbTrainingByWeek: [2, 3],
      // },
      // {
      //   niveau: "Séance à thème",
      //   startAge: 15,
      //   NbTrainingByWeek: [1],
      // },
    ],

    priceDataAdulte: [
      // {
      //   niveau: "loisir",
      //   NbTrainingByWeek: 1,
      //   price: 195,
      // },
      // {
      //   niveau: "loisir",
      //   NbTrainingByWeek: 3,
      //   price: 200,
      // },
      // {
      //   niveau: "loisir",
      //   NbTrainingByWeek: 6,
      //   price: 270,
      // },
      // {
      //   niveau: "body taek fit",
      //   NbTrainingByWeek: 1,
      //   price: 195,
      // },
      // {
      //   niveau: "body taek fit",
      //   NbTrainingByWeek: 2,
      //   price: 270,
      // },
      // {
      //   niveau: "compétition",
      //   NbTrainingByWeek: 2,
      //   price: 195,
      // },
      // {
      //   niveau: "compétition",
      //   NbTrainingByWeek: 3,
      //   price: 270,
      // },
      // {
      //   niveau: "Séance à thème",
      //   NbTrainingByWeek: 1,
      //   price: 150,
      // },
    ],
    // A generer avec export excel programme python vers JSON ou Data base
    paiementModaliteDataAdulte: [],
  },
  getters: {
    getPaiementModaliteDataAdulte: (state) => {
      return state.paiementModaliteDataAdulte;
    },
    getResetAdulte: (state) => {
      return state.resetAdulte;
    },
    getSaveInscriptionAdulte: (state) => {
      return state.saveInscriptionAdulte;
    },
    getHoraireDataAdulte: (state) => {
      return state.horaireDataAdulte;
    },
    getIncrementAdulte: (state) => {
      return state.incrementAdulte;
    },

    getLevelDataAdulte: (state) => {
      return state.levelDataAdulte;
    },
    getPriceDataAdulte: (state) => {
      return state.priceDataAdulte;
    },
  },
  actions: {
    setResetAdulte({ commit }, datas) {
      commit("SET_RESET_ADULTE", datas);
    },
    setSaveInscriptionAdulte({ commit }, datas) {
      commit("SET_SAVE_INSCRIPTION_ADULTE", datas);
    },
    incrementInitAdulte({ commit }) {
      commit("INCREMENT_INIT_ADULTE");
    },
    desincrementInitAdulte({ commit }) {
      commit("DESINCREMENT_INIT_ADULTE");
    },
    setReglageAdulte({ commit }, datas) {
      commit("SET_REGLAGE_ADULTE", datas);
    },
  },
  mutations: {
    SET_REGLAGE_ADULTE(state, datas) {
      // console.log("reglage");
      state.horaireDataAdulte = datas.horaireData;
      state.levelDataAdulte = datas.levelData;
      state.priceDataAdulte = datas.priceData;
      state.paiementModaliteDataAdulte = datas.paiementData;
    },
    SET_RESET_ADULTE(state, datas) {
      // console.log("reset", datas);
      state.resetAdulte = datas;
    },
    SET_SAVE_INSCRIPTION_ADULTE(state, datas) {
      if (datas.typeForm == "personnalInformation") {
        state.saveInscriptionAdulte["personnalInformation"] = datas.value;
      }

      // A set dans une fonction a part
      if (datas.typeForm == "pieceJointe") {
        state.saveInscriptionAdulte["pieceJointe"] = datas.value;
      }

      if (datas.typeForm == "contactUrgence") {
        state.saveInscriptionAdulte["contactUrgence"] = datas.value;
      }

      if (datas.typeForm == "niveau") {
        state.saveInscriptionAdulte["niveau"] = datas.value;
      }

      if (datas.typeForm == "creneau") {
        state.saveInscriptionAdulte["creneau"] = datas.value;
      }

      if (datas.typeForm == "cotisation") {
        state.saveInscriptionAdulte["cotisation"] = datas.value;
      }

      if (datas.typeForm == "moyens") {
        // state.saveInscriptionAdulte["moyens"] = datas.value.paiement;
        // state.saveInscriptionAdulte["ref"] = datas.value.ref;
        state.saveInscriptionAdulte["optIn"] = datas.value.optIn;
        state.saveInscriptionAdulte["saison"] = datas.value.saison;
        state.saveInscriptionAdulte["type"] = datas.value.type;
        state.saveInscriptionAdulte["ref_adherent"] = datas.value.ref_adherent;
      }

      if (datas.typeForm == "ref") {
        state.saveInscriptionAdulte["ref"] = datas.value.ref;
      }
      // console.log("saveInscriptionAdulte", state.saveInscriptionAdulte);
    },
    INCREMENT_INIT_ADULTE(state) {
      state.incrementAdulte.init =
        state.incrementAdulte.init <= state.incrementAdulte.max
          ? state.incrementAdulte.init + 1
          : state.incrementAdulte.init;
    },
    DESINCREMENT_INIT_ADULTE(state) {
      state.incrementAdulte.init = state.incrementAdulte.init - 1;
    },
  },
};
