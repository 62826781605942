<script>
import { mapActions, mapState } from "vuex";
import router from "@/router";
import formMixin from "@/mixins/formMixin";
export default {
  data: () => {
    return {
      scroll: { sticky: false },
      nav: document.querySelector("nav"),
      openMenu: false,
      icons: [
        {
          mdi: "mdi-facebook",
          link: "https://www.facebook.com/GTAcademie93/",
        },
        // { mdi: "mdi-twitter", link: "" },
        // { mdi: "mdi-linkedin", link: "http://www.linkedin.com" },
        {
          mdi: "mdi-instagram",
          link: "https://www.instagram.com/gtacademie93/",
        },
      ],
      links: ["A propos", "FAQ", "Team", "Site web"],
    };
  },

  computed: {
    ...mapState("Inscription", ["modal", "modalContent"]),
    modalModel: {
      get() {
        return this.modal;
      },
      set(val) {
        this.setModelModal(val);
      },
    },
  },
  methods: {
    onScroll() {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 20) {
          this.scroll = { sticky: true };
        } else {
          this.scroll = { sticky: false };
        }
      };
    },
    ...mapActions("Inscription", ["setModelModal", "setModalContent"]),
    deconnexion() {
      this.$store.state.Inscription.user = false;
      localStorage.removeItem("token");
      localStorage.removeItem("compteType");
      localStorage.removeItem("refreshToken");
      this.$router.push("/login");
      this.$store.state.Inscription.lastUserConfig = {};
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  mixins: [formMixin],
  mounted() {
    router.afterEach(() => {
      this.openMenu = false;
      document.documentElement.scrollTop = 0;
    });
    this.getInscription();
  },
};
</script>
<template>
  <v-app v-scroll="onScroll">
    <!-- navigation -->
    <nav :class="[scroll, { open: openMenu }]">
      <div class="nav-content" :class="{ open: openMenu }">
        <div
          class="menu-icon"
          @click="openMenu = !openMenu"
          :class="{ 'is-opened': openMenu }"
        >
          <span></span>
        </div>
        <div class="logo">
          <router-link to="/">Inscription-online.</router-link>
        </div>
        <ul class="nav-links" :class="{ open: openMenu }">
          <li><router-link to="/">Accueil</router-link></li>
          <li><router-link to="/enfant">Enfant</router-link></li>
          <li><router-link to="/adulte">Adulte</router-link></li>
          <li><router-link to="/reglement">Règlement</router-link></li>
          <li>
            <router-link to="/informations-legales"
              >Informations légales</router-link
            >
          </li>
          <!-- <li>
            <a
              @click.stop="
                setModelModal(true);
                setModalContent(1);
              "
              >Règlement</a
            >
          </li> -->
          <!-- <li>
            <a
              @click.stop="
                setModelModal(true);
                setModalContent(2);
              "
              >Informations légales</a
            >
          </li> -->
          <li>
            <router-link
              class="outlined"
              :to="$store.state.Inscription.user ? '/compte' : '/login'"
              >Compte</router-link
            >
          </li>
          <li>
            <v-icon
              v-if="$store.state.Inscription.user"
              @click="deconnexion()"
              class="ma-0 logout"
              color="#ffffff"
              x-large
              >mdi-exit-to-app</v-icon
            >
          </li>
        </ul>
      </div>
    </nav>
    <!-- navigation -->
    <router-view />
    <!-- footer -->
    <v-footer
      style="background-color: #4c4c4c; z-index: 1; bottom: 0 !important"
      dark
      padless
    >
      <v-card flat tile color="#4c4c4c" class="footer white--text text-center">
        <v-card-text>
          <v-btn
            v-for="(icon, cle) in icons"
            :key="cle"
            class="mx-4 white--text"
            icon
            :href="icon.link"
            target="_blank"
          >
            <v-icon size="24px">
              {{ icon.mdi }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- <v-card-text class="white--text pt-0">
          <v-btn
            v-for="(link, cle) in links"
            :key="cle"
            color="white"
            text
            rounded
            class="my-2"
          >
            {{ link }}
          </v-btn>
        </v-card-text> -->

        <v-divider></v-divider>

        <v-card-text class="white--text">
          Copyright © {{ new Date().getFullYear() }} —
          <strong>Inscription-online.</strong>
          <!-- Tous droits réservés. -->
        </v-card-text>
      </v-card>
    </v-footer>
    <!-- footer -->
    <v-dialog
      transition="dialog-top-transition"
      max-width="90%"
      v-model="modalModel"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="indigo dark-1" dark class="">{{
            modalContent.titre
          }}</v-toolbar>
          <v-card-text>
            <div class="pa-2" v-html="modalContent.corps"></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="grey dark-1" @click="dialog.value = false"
              >Fermer</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-app>
</template>
<!-- Footer -->
<style>
.footer {
  min-width: 1200px;
  margin: auto;
  background-color: #4c4c4c;
}

@media (max-width: 1020px) {
  .footer {
    min-width: auto;
  }
}
</style>
<!-- Navigation -->
<style>
.menu-icon {
  display: none;
}
/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Ubuntu", sans-serif;
}
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  transition: all 0.4s ease;
  z-index: 150;
}
nav.sticky {
  padding: 15px 20px;
  background: #4c4c4c;
  /* background: #4070f4; */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
nav .nav-content {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .logo a {
  font-weight: 500;
  font-size: 35px;
  color: #ffffff;
  /* color: #4070f4; */
}
nav.sticky .logo a {
  color: #fff;
}
.nav-content .nav-links {
  display: flex;
  align-items: center;
}
.nav-content .nav-links li {
  list-style: none;
  margin: 0 8px;
}

.outlined {
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.outlined:hover {
  border: 2px solid #ffffff;
}
.sticky .outlined {
  border: 2px solid #ffffff;
}
.sticky .outlined:hover {
  border: 2px solid #ffffff;
}

.nav-links li a {
  text-decoration: none;
  color: #ffffff;
  /* color: #0e2431; */
  font-size: 18px;
  font-weight: 500;
  padding: 10px 4px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  color: rgba(255, 255, 255, 0.6);
}
nav.sticky .nav-links li a {
  color: #fff;
  transition: all 0.4s ease;
}
nav.sticky .nav-links li a:hover {
  color: rgba(255, 255, 255, 0.6);
}
.home {
  height: 100vh;
  width: 100%;
  /* background: url("images/background.png") no-repeat; */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: "Ubuntu", sans-serif;
}
h2 {
  font-size: 30px;
  margin-bottom: 6px;
  color: #4070f4;
}
.text {
  text-align: justify;
  padding: 40px 80px;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1020px) {
  .v-icon.logout {
    margin-top: 15px !important;
  }
  nav .nav-content {
    /* flex-direction: column !important; */
    justify-content: center !important;
    /* width: 100%; */
    max-width: 320px !important;
  }

  nav .nav-content.open {
    flex-direction: column !important;
    /* justify-content: center !important; */
  }

  nav .logo a {
    font-size: 25px;
  }
  nav.open .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  nav.open .logo a {
    color: #ffffff;
  }

  .nav-content .nav-links {
    display: none;
  }

  nav.open .menu-icon span::before,
  nav.open .menu-icon span::after {
    background-color: #ffffff;
  }

  nav.open {
    position: fixed;
    background: #4c4c4c;
  }

  nav.open .outlined {
    margin-top: 10px;
    border: 2px solid #ffffff;
  }
  .nav-content .nav-links.open {
    display: block;
    text-align: center;
    padding: 0;
  }
  .nav-content .nav-links.open a {
    display: block;
    text-align: center;
    color: #ffffff;
  }

  .menu-icon {
    display: flex;
    position: relative;
    width: 54px;
    height: 54px;

    transition: transform 0.5s;
  }
}

.menu-icon span {
  position: absolute;
  top: 50%;
  left: 0;
  /* transform: translateX(-50%) translateY(-50%); */

  display: block;
  width: 22px;
  height: 2px;

  background-color: #ffffff;

  transition: background 0.5s;
}

.menu-icon span::before,
.menu-icon span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-6px);

  display: block;
  width: 22px;
  height: 2px;

  background-color: #ffffff;

  transition: transform 0.5s;
}

nav.sticky .menu-icon span::before,
nav.sticky .menu-icon span::after,
nav.sticky .menu-icon span {
  background-color: #ffffff;
}

nav.sticky .menu-icon.is-opened span {
  background: transparent;
}

.menu-icon span::after {
  transform: translateY(6px);
}

.menu-icon.is-opened span {
  background: transparent;
}
.menu-icon.is-opened span::before {
  transform: translateY(0) rotate(45deg);
}
.menu-icon.is-opened span::after {
  transform: translateY(0) rotate(-45deg);
}
.menu-icon.is-opened {
  transform: rotate(90deg);
}
</style>
