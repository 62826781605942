import Vue from "vue";
import Vuex from "vuex";

import Adulte from "./Adulte";
import Enfant from "./Enfant";
import Inscription from "./Inscription";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    Adulte,
    Enfant,
    Inscription,
  },
});
